import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import axios from "axios";
import { conditionalExp } from "../utils/commonFn";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { getMomentFormatted, getStatusIcon } from "../utils/commonFn";
import { DatePicker, Space } from 'antd';
import moment from "moment";


function Delivery_history() {
  const navigate = useNavigate()

 const [selectedDeliveryDate, setselectedDeliveryDate] = useState()

  const [isLoading, setisLoading] = useState(false);
  const [selectedDeliveryType, setselectedDeliveryType] = useState({label:'Distribution',value:'distribution'});
  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => {setShowopen(false)
    setSkuModalData(
      {
        doc_number: "",
        order_items: [],
      }
    )
  };
  const handleShowopen = (data) => {
    setShowopen(true);
    setSkuModalData(data);
  };

  const [skuModalData, setSkuModalData] = useState({
    doc_number: "",
    order_items: [],
  })
  const [accordion, setAccordion] = useState([]);

  async function getDeliveryAccordion() {
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))
      ?.career
      ?.career_id;
    setisLoading(true);

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdeliveryhistory/get_delivered_orders`,
        { career_id: career_id, delivery_type: selectedDeliveryType?.value,delivery_date: selectedDeliveryDate && moment(selectedDeliveryDate).format("YYYY-MM-DD")},
        // { career_id: career_id,delivery_type: selectedDeliveryType?.value},
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setisLoading(false);
        setAccordion(response?.data?.drivers);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
      if (err?.response && err?.response.status === 401) {
        localStorage?.removeItem("mtm_user_token");
        localStorage?.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  

  useEffect(() => {
    getDeliveryAccordion();
  }, [selectedDeliveryType,selectedDeliveryDate]);

  

  return (
    <>
        <Container>
                <Row>
                <Col md={12}>
                    <div className="wrapper-box">
                        <button
                            type="button"
                            className="btn-back"
                            onClick={() => navigate("/")}
                        >
                            <svg className="icon fs-30">
                            <use href="#icon_leftarrow"></use>
                            </svg>
                        </button>
                        <h2 className="text-center fw-700 fs-30">Delivery History</h2>
                        </div>
                    <div className="d-flex align-items-center">
                        <div className="d-flex gap-4 mb-3">
                            <div className="select-drop">
                                <label htmlFor="pickup" className="form-label">
                                Delivery Type
                                </label>
                                <Select
                                value={selectedDeliveryType}
                                options={[{label:'Import/Export',value:'importation'},{label:'Distribution',value:'distribution'}]}
                                onChange={(e) => {
                                    setselectedDeliveryType(e);
                                }}
                                />
                            </div>

                            <div>
                                <label htmlFor="date" className="form-label d-block">
                                    Delivery Date
                                </label>
                                <Space direction="vertical">
                                    <DatePicker value={selectedDeliveryDate} onChange={setselectedDeliveryDate} size="large"/>
                                </Space>
                            </div>
                        </div>
                  </div>

                  {/* accor */}
                  {selectedDeliveryType.value === 'importation' && (
                  <div className="accordion" id="accordionExample">
                    {accordion.map((item, index) => (
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle={`collapse`}
                            // data-bs-toggle="collapse"
                            data-bs-target={`#collapseTwo${index}`}
                            // data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls={`collapseTwo${index}`}
                            // aria-controls="collapseTwo"
                          >
                            {item?.first_name} {item?.last_name} 

                          </button>
                        </h2>
                        <div
                          id={`collapseTwo${index}`}
                        //   id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row row-cols-3 g-4">
                              {item?.orders?.map((order, index) => (
                                <div className="col">
                                  <div className="list-boxes">
                                    <ul>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_purchase"></use>
                                        </svg>
                                        Purchase Order: {order?.doc_number}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_total"></use>
                                        </svg>
                                        Total Quantity: {order?.total_qty} CBM ({order?.cbm})
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_truck1"></use>
                                        </svg>
                                        Pickup Date: {order?.departure_date}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_truck2"></use>
                                        </svg>
                                        Pickup Location: {order?.pickup_location}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_delivery"></use>
                                        </svg>
                                        Delivery Location: {" "} {order?.delivery_location}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_delivery"></use>
                                        </svg>
                                        Type Of Truck: {order?.container_type}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  )}

                  {/* accordion start */}
                  {selectedDeliveryType?.value === 'distribution' && (
                    <div className="accordion my-3" id="accordionExample">
                        {accordion?.map((item,index) => (
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle={`collapse`}
                                    //   data-bs-toggle="collapse"
                                    data-bs-target={`#collapseTwo${index}`}
                                    //   data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls={`collapseTwo${index}`}
                                    //   aria-controls="collapseTwo"
                                    >
                                    <div className="w22 fs-14">
                                    {item?.group_name} (ID: {item?.group_id})
                                    {/* Group Name (ID:) */}
                                        
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center w-75 text-dark">
                                        <div className="fs-14 fw-600 br-left br-right px-2 ms-5">
                                          <p className="pb-2 fs-11 fw-700">
                                            Delivery date & Time
                                          </p>
                                          <div className="d-flex fs-14 fw-400">
                                            {getMomentFormatted(item?.delivery_date,'DD/MM/YYYY | hh:mm A')}
                                          </div>
                                        </div>
                                        <div className="fs-14 fw-600 br-left px-2 ms-5">
                                          <p className="pb-2 fs-11 fw-700">
                                            PickUp Date & Time
                                          </p>
                                          <div className="d-flex fs-14 fw-400">
                                            <div className="br-right px-1">
                                            {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                            </div>
                                            <div className="px-1">
                                            {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                            </div>
                                          </div>
                                        </div>
                                        {conditionalExp(item?.dispatch_date,
                                        <div className="fs-14 fw-600 br-left br-right px-2">
                                          <p className="pb-2 fs-11 fw-700">
                                              Dispatch Date & Time
                                          </p>
                                          <div className="d-flex fs-14 fw-400">
                                            <div className="br-right px-1">{item?.dispatch_date}</div>
                                            <div className="px-1">11:02 AM</div>
                                            {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                                          </div>
                                        </div>
                                        )}
                                    </div>
                                    </button>
                                </h2>
                                <div
                                    id={`collapseTwo${index}`}
                                    // id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                    <div className="row row-cols-2 g-4">
                                        {item?.orders?.map((order, index) => (
                                        <div className="col">
                                            <div className="list-boxes">
                                            <ul>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_purchase"></use>
                                                </svg>
                                                    Order Number: {order?.doc_number} 
                                                </li>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_total"></use>
                                                </svg>
                                                    Total Quantity: {order?.total_qty}
                                                <div className="eye-btn2 ms-4">
                                                    <button
                                                    type="button"
                                                    className="p-0 m-0 border-0"
                                                      onClick={()=>handleShowopen({
                                                        doc_number: order?.doc_number,
                                                        order_items: order?.order_items,
                                                      })}
                                                    >
                                                    <svg className="icon fs-18">
                                                        <use href="#icon_eye"></use>
                                                    </svg>
                                                    </button>
                                                </div>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_customer"></use>
                                                </svg>
                                                    Customer: {order?.organization_name}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_appointment"></use>
                                                </svg>
                                                    Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_truck2"></use>
                                                </svg>
                                                    Delivery Address: {order?.address1}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                <svg className="icon me-2">
                                                    <use href="#icon_delivery"></use>
                                                </svg>
                                                    Type Of Truck: {order?.truck_type}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <svg className="icon me-2">
                                                        <use href="#icon_delivery"></use>
                                                    </svg>
                                                    Truck Line: {order?.truck_line}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <svg className="icon me-2">
                                                        <use href="#icon_booking-id"></use>
                                                    </svg>
                                                    Booking ID: {order?.booking_id}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <svg className="icon me-2">
                                                        <use href="#icon_truck-no"></use>
                                                    </svg>
                                                    Truck No: {order?.truck_no}
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    </div>
                                </div>
                            </div>
                         ))}
                    </div>
                  )}
                  {/* accordion end */}
                  {conditionalExp(
                    isLoading == false && accordion.length == 0,
                    <p className="w-full text-center fs-2 py-5">No delivery history found</p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </Col>
            </Row>
        </Container>


        <Modal size="lg" scrollable={true} show={showopen} centered onHide={handleCloseopen}>
            <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3 pt-0">
            <h4 className="text-center m-0 pb-3 fs-22 fw-700">
                Order Number: 
                {skuModalData?.doc_number}
            </h4>
            <div className="new-tbl">
                <table className="table">
                <thead>
                    <tr>
                    <th className="text-start">SKU</th>
                    <th className="text-start">Descripton</th>
                    <th className="text-start">Order Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {skuModalData?.order_items.map((itm)=>{
                    console.log(itm)
                    return (<tr>
                        <td>{itm?.item_code}</td>
                        <td>{itm?.description}</td>
                        <td>{itm?.qty}</td>
                    </tr>)
                    })}
                </tbody>
                
                </table>
            </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default Delivery_history