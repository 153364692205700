import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useEffect } from "react";

function LayoutOutlet() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("mtm_user_token") || !localStorage.getItem("mtm_user_data")) {
            navigate("/login");
            return
          }
    }, [])
    
    return(<>
        <NavBar></NavBar>
        <div className="wrapper">
            <Outlet></Outlet>
        </div>
    </>)
}
export default LayoutOutlet;