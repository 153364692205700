import {React,useState} from 'react'
import {Form, Link,useNavigate } from "react-router-dom";
import {Container, Row, Col, ProgressBar,Modal } from 'react-bootstrap';
import NavBar from '../components/NavBar'

function Details() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <NavBar/>
    <div className='bg-gray'>
      <Container>
        <Row>
          <Col md={12}>
            <div className='wrapper-box'>
              <div className='position-relative'>
                <h2 className='text-center fw-700 fs-30'>Request for Quote</h2>
              </div>
              <div className='content-wrapper'>
                <form>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Opportunity Detail</label>
                        <input type="text" className=" w-100" placeholder="Vineet Joshi"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="abc@gmail.com"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Type of product(service)</label>
                        <input type="text" className=" w-100" placeholder="Intermodal"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Service Details</label>
                        <input type="text" className=" w-100" placeholder="Door to Door"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Container Type</label>
                        <input type="text" className=" w-100" placeholder="Intermodal"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Origin</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Destination</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Pick up Address</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="0022"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Delivery Address</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="0033"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Import / Export</label>
                        <input type="text" className=" w-100" placeholder="Export"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Monthly Volume</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Cargo Details</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="0011"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="0022"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Request Detail</label>
                        <input type="text" className=" w-100" placeholder="Yes"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Trade</label>
                        <input type="text" className=" w-100" placeholder="Latam"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0 pb-3'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Required Free Time</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                    <div className="col">
                    </div>
                  </div>
                  <div className='row gap-sm-5 gap-0'>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">Insurance Details</label>
                        <input type="text" className=" w-100" placeholder="Yes"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className='border-bottom py-3'>
                        <label className="form-label fw-600 fs-20">&nbsp;</label>
                        <input type="text" className=" w-100" placeholder="lorem ipsum"/>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end py-3'>
                    <div>
                      <button type='button' className='px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4' onClick={() => navigate("/pagefifteen")}>
                        <svg className="icon me-2 fs-24">
                        <use href="#icon_leftarrow"></use>
                        </svg>
                        Back
                      </button>
                    </div>
                    <div>
                      <button type='button' className='px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center' onClick={handleShow}>
                        Next 
                        <svg className="icon ms-2 fs-24">
                        <use href="#icon_rightarrow"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    {/* ==== Modal ==== */}
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className='text-center py-5'>
        <div className='d-flex justify-content-center'>
          <div className='check-box'>
            <svg className="icon fs-30">
              <use href="#icon_check"></use>
            </svg>
          </div>
        </div>
        <p className='fw-600 fs-26 py-4'>Thank you</p>
        <p className='fw-400 fs-20'>Your Submission Has Been Received</p>
      </Modal.Body>
    </Modal>
    </>
  )
}

export default Details