import { BrowserRouter,Routes ,Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.js";
import Login from '../pages/Login'
import Details from '../pages/Details'
import LayoutOutlet from './LayoutOutlet'
import Home from '../pages/Home'
import Manage_driver from '../pages/Manage_driver';
import Assign_order from '../pages/Assign_order';
import CustomerAppointment from '../pages/Customer_appointment';
import Save2 from '../pages/Save2';
import Delivery_history from '../pages/Delivery_history';

function Routing() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/saved" element={<Save2 />}></Route>
                <Route path="/drv/appoint/:appoint_uniq_id" element={<CustomerAppointment />}></Route>
                <Route path="/" element={<LayoutOutlet />}>
                    <Route path='/' element={<Home />}/>
                    <Route path="/manage-driver" element={<Manage_driver />}></Route>
                    <Route path="/assign-order" element={<Assign_order />}></Route>
                    <Route path="/details" element={<Details />}></Route>
                    <Route path="/delivery-history" element={<Delivery_history />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    </>)
}

export default Routing;
