import axios from "axios";
import moment from "moment";

export const getPOLPorts = async (
  setOptionsForPortsOrigin,
  container_type,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/getport`,
      method: "POST",
      data:{
        container_type: container_type,
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      let portloadings = [];
      let pol = response?.data?.portloadings;
      for (let i of pol) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_loading}`;
        obj["value"] = `${element?.port_of_loading}`;
        obj["country"] = `${element?.origin_country}`;
        portloadings.push(obj);
      }
      

      setOptionsForPortsOrigin && setOptionsForPortsOrigin(portloadings);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};
export const getPODPorts = async (
  setOptionsForPortsDestination,
  container_type,
  pol,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/getportdischarge`,
      method: "POST",
      data:{
        container_type: container_type,
        pol:pol
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      let portdischarge = [];
      let pod = response?.data?.portdischarge;
      for (let i of pod) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_discharge}`;
        obj["value"] = `${element?.port_of_discharge}`;
        portdischarge.push(obj);
      }
      

      setOptionsForPortsDestination && setOptionsForPortsDestination(portdischarge);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getSKUListFootprint = async (setSkuListDetailsRaw, navigate) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/item/details`,
      method: "POST",
      data:{
        customer_id:JSON.parse(localStorage.getItem("mtm_user_data"))
        ?.customer_id
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      setSkuListDetailsRaw(response.data.items);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getFormattedDate = (item) => {
  const currentDate = item["$d"];
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getDashboardData = async (
  selectedDate,
  navigate,
  {setDashboardRawDataMap,
    setDashboardRawData,
    setStatusPieList,
    setIsLoading,
    setShowErrModal,
    setError,setStatuses,setContainerTypeList,setCountryContainerList,setShippingLineContainerList
  },
  selectedContainerType
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");
    let user = JSON.parse(localStorage.getItem("mtm_user_data"));
    setIsLoading(true);
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getdashboard`,
      method: "POST",
      data: {
        from_date: selectedDate[0],
        to_date: selectedDate[1],
        container_type:selectedContainerType?.value || null,
        customer_id: user?.customer_id
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      setDashboardRawDataMap(response.data?.booking_container_map);
      setDashboardRawData(response.data?.booking_container);
      setStatusPieList(response.data?.status_container);
      setStatuses(response.data?.booking_status);
      setContainerTypeList(response.data?.container_type);
      setCountryContainerList(response.data?.country_container);
      setShippingLineContainerList(response.data?.shipping_line_container);
    } else {
      setError({ title: "Error", err: response?.data?.message });
      setShowErrModal(true);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  } finally {
    setIsLoading(false);
  }
};

export function getStatusIcon(status,size) {
  switch (status) {
    case "Booking confirmed":
      return<div className={`bg-lightpurple ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-purple rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_origin"></use>
        </svg>
      </div>
      <span className="fw-600 text-purple">
        {status}
      </span>
    </div> 
  
    case "At port of loading":
      return <div className={`bg-lightcyan ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-cyan rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_portloding"></use>
        </svg>
      </div>
      <span className="fw-600 text-cyan">
        {status}
      </span>
    </div> 
  
    case "Ocean in transit":
      return <div className={`bg-lightblue ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_transit"></use>
        </svg>
      </div>
      <span className="fw-600 text-blue">
        {status}
      </span>
    </div>
  
    case "Near arrival":
      return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_portdischarge"></use>
        </svg>
      </div>
      <span className="fw-600 text-pink">
        {status}
      </span>
    </div>
  
    case "At port of destination":
      return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_portdischarge"></use>
        </svg>
      </div>
      <span className="fw-600 text-pink">
        {status}
      </span>
    </div>
  
    case "On route to warehouse":
      return <div className={`bg-lightyellow ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-yellow rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_house"></use>
        </svg>
      </div>
      <span className="fw-600 text-yellow">
        {status}
      </span>
    </div>
  
    case "At warehouse":
      return <div className={`bg-lightyellow ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-yellow rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_house"></use>
        </svg>
      </div>
      <span className="fw-600 text-yellow">
        {status}
      </span>
    </div>
  
    case "Delivered":
      return <div className={`bg-lightgreen ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_delivered"></use>
        </svg>
      </div>
      <span className="fw-600 text-green">
        {status}
      </span>
    </div>
  
    default:
      return <div className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-pink rounded-2 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_origin"></use>
        </svg>
      </div>
      <span className="fw-600 text-pink">
        {status}
      </span>
    </div>;
     
  }
}

export function conditionalExp(condition,a,b){
  if(condition){
    return a
  }else{
    return b
  }
}

export function getDemandFactor (date,mtm_user_data){
  if(mtm_user_data?.demand_forcast_type == 'weekly' && date){
    return  date?.week()
  }else if(mtm_user_data?.demand_forcast_type =='monthly' && date){
    return date?.month() + 1;
  }else if(mtm_user_data?.demand_forcast_type == 'quarterly' && date){
    return Math.ceil((date?.month() + 1) / 3)
  }else{
    return null
  }
}

export function getMomentFormatted(date,format){
  if(date){
    return moment(date).format(format)
  }else {
   return "";
  }
}