import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { conditionalExp } from "../utils/commonFn";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { getMomentFormatted, getStatusIcon } from "../utils/commonFn";


function Assign_order() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false);
  setBookingId();setTruckNo();setSelectedOption();
  };
  const handleShow = (from) => setShow(from);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  const [selectedOptionPickup, setselectedOptionPickup] = useState();
  const [selectedDeliveryType, setselectedDeliveryType] = useState({label:'Distribution',value:'distribution'});
  const [selectedOptionDelivery, setselectedOptionDelivery] = useState();

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [pickupLocations, setPickupLocations] = useState([]);
  const [notAssigned, setNotAssigned] = useState([]);
  const [filterNotAssigned, setfilterNotAssigned] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingAssign, setisLoadingAssign] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);


  // modal
  // Add state variable for success modal visibility
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Function to handle closing of the success modal
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  // Function to handle opening of the success modal
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => {setShowopen(false)
    setSkuModalData(
      {
        doc_number: "",
        order_items: [],
      }
    )
  };
  const handleShowopen = (data) => {
    setShowopen(true);
    setSkuModalData(data);
  };

  const [skuModalData, setSkuModalData] = useState({
    doc_number: "",
    order_items: [],
  })

  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [sucessMsg, setSucessMsg] = useState("");
  const [bookingId, setBookingId] = useState()
  const [truckNo, setTruckNo] = useState()
  const [accordion, setAccordion] = useState([]);

  async function getAssignAccordion() {
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))
      ?.career
      ?.career_id;
      setisLoading(true)
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmorder/get_assigned_orders`,
        { career_id: career_id,delivery_type: selectedDeliveryType?.value},
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setAccordion(response?.data?.drivers);
      } else {
        setAccordion([])
      }
    } catch (err) {
      if (err?.response && err?.response.status === 401) {
        localStorage?.removeItem("mtm_user_token");
        localStorage?.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }finally{
      setisLoading(false)
    }
  }

  async function assignOrders() {
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))?.career
    ?.career_id;
    setisLoadingAssign(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmorder/assign_orders`,
        {
          career_id: career_id,
          booking_id: bookingId,
          truck_no: truckNo,
          previous_driver_id: show == 'notassigned' ? null : show?.previous_driver_id,
          driver_id: selectedOption?.value,
          orders: show == 'notassigned' ? filterNotAssigned?.filter(
            (order) => order?.isChecked == true
          ): show?.orders?.filter((order) => order?.isChecked == true),
          group_id:show?.group_id || null,
          delivery_type: selectedDeliveryType?.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        getNotAssigned();
        getAssignAccordion();
        getDrivers()
        setSelectedOption(null);
        handleClose();
        handleShowSuccessModal();
        setTimeout(() => {
          setSucessMsg("");
          setShowSuccessModal(false);
        }, 2000);
      } else {
        setisLoading(false);
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      setError({ title: "Error", err: "somthing went wrong" });
      setShowErrModal(true);
    } finally {
      setisLoadingAssign(false);
    }
  }

  async function getDrivers() {
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))?.career
      ?.career_id;
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdriver/get_driver_list`,
        { career_id: career_id },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setDrivers(
          response?.data?.drivers?.map((element) => {
            return {
              label: `${element?.first_name} ${element?.last_name}`,
              value: element?.driver_id,
              driver_status: element?.driver_status
            };
          })
        );
      }
    } catch (err) {
      if (err?.response && err?.response.status === 401) {
        localStorage?.removeItem("mtm_user_token");
        localStorage?.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getNotAssigned() {
    const token = localStorage.getItem("mtm_user_token");
    const career_scac = JSON.parse(localStorage.getItem("mtm_user_data"))
      ?.career?.career_scac;

    setisLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmorder/get_not_assigned_orders`,
        { career_code: career_scac , delivery_type:selectedDeliveryType?.value},
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setisLoading(false);
        setNotAssigned(
          response.data?.orders?.map((order) => ({
            ...order,
            isChecked: false,
          }))
        );
        setfilterNotAssigned(
          response.data?.orders?.map((order) => ({
            ...order,
            isChecked: false,
          }))
        );
        let newPickuplocation = [
          ...new Set(
            response.data?.orders.map((order) => order.pickup_location)
          ),
        ]?.map((location) => ({ label: location, value: location }));
        setPickupLocations(newPickuplocation);

        let newDeliverylocation = [
          ...new Set(
            response.data?.orders.map((order) => order.delivery_location)
          ),
        ]?.map((location) => ({ label: location, value: location }));
        setDeliveryLocations(newDeliverylocation);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      // console.log(err);
    }
  }
  useEffect(() => {
    getDrivers();
  }, []);

  useEffect(() => {
    setNotAssigned([])
    setfilterNotAssigned([])
    setAccordion([])
    getNotAssigned();
    getAssignAccordion();
  }, [selectedDeliveryType]);

  useEffect(() => {
    if (
      !selectedOptionPickup?.value &&
      !selectedOptionDelivery?.value
    ) {
      setfilterNotAssigned(
        notAssigned?.map((order) => ({
          ...order,
          isChecked: false,
        }))
      );
      setDeliveryLocations([
              ...new Set(
                notAssigned?.map((order) => order.delivery_location)
              ),
            ]?.map((location) => ({ label: location, value: location })))
      setPickupLocations([
              ...new Set(
                notAssigned?.map((order) => order.pickup_location)
              ),
            ]?.map((location) => ({ label: location, value: location })))
    } else if (
      selectedOptionPickup?.value &&
      selectedOptionDelivery?.value
    ) {
      let filterArray = [];
      filterArray = notAssigned.filter((item) =>
      selectedOptionPickup?.value  == item?.pickup_location
      );
      filterArray = filterArray.filter((item) =>
        selectedOptionDelivery?.value==item?.delivery_location
      );
      setfilterNotAssigned(
        filterArray?.map((order) => ({
          ...order,
          isChecked: false,
        }))
      );
    } else {
      let filterArray = [];
      if (
        selectedOptionPickup?.value &&
        !selectedOptionDelivery?.value
      ) {
        filterArray = notAssigned.filter((item) =>
          selectedOptionPickup?.value == item?.pickup_location
        );
      } else if (
        selectedOptionDelivery?.value &&
        !selectedOptionPickup?.value
      ) {
        filterArray = notAssigned.filter((item) =>
          selectedOptionDelivery?.value == item?.delivery_location
        );
      }

      setfilterNotAssigned(
        filterArray?.map((order) => ({
          ...order,
          isChecked: false,
        }))
      );
    }
  }, [selectedOptionPickup, selectedOptionDelivery]);



  // useEffect(() => {
  //   if(selectedOptionPickup?.value){
  //     let filterArray = notAssigned?.filter((item) => item?.pickup_location == selectedOptionPickup?.value);

  //     setfilterNotAssigned(filterArray)
  //   }else{
  //     setDeliveryLocations([
  //       ...new Set(
  //         notAssigned?.map((order) => order.delivery_location)
  //       ),
  //     ]?.map((location) => ({ label: location, value: location })))
  //     setfilterNotAssigned(
  //             notAssigned?.map((order) => ({
  //               ...order,
  //               isChecked: false,
  //             }))
  //           );
  //   }
  // }, [selectedOptionPickup]);

  // useEffect(() => {
  //   if(selectedOptionDelivery?.value){
  //     if(selectedOptionPickup?.value && !selectedOptionDelivery?.value){
  //     let filterArray = notAssigned?.filter((item) => item?.pickup_location == selectedOptionPickup?.value);
  //     setfilterNotAssigned(filterArray)
  //     return;
  //     }
  //     let filterArray = notAssigned?.filter((item) => item?.delivery_location == selectedOptionDelivery?.value);

  //     setfilterNotAssigned(filterArray)
  //   }else{
  //     setfilterNotAssigned(
  //             notAssigned?.map((order) => ({
  //               ...order,
  //               isChecked: false,
  //             }))
  //           );
  //   }
  // }, [selectedOptionDelivery]);

  const checkselected = (e, index, item) => {
    let newArray = [...filterNotAssigned];
    newArray[index] = { ...item, isChecked: e.target.checked };
    setfilterNotAssigned(newArray);
  };
  const checkselectedAssined = (e, index, item,order) => {
    let newArray = [...accordion];
    for (let i = 0; i < newArray.length; i++) {
      const driver =newArray[i];
      if(driver?.driver_id==item?.driver_id){
        let newOrders=[...driver?.orders];
        newOrders[index] = { ...order, isChecked: e.target.checked };
        newArray[i].orders=newOrders;
        break;
      }
    }
    setAccordion(newArray);
  };
  const uncheckFn=(item)=>{
    let newArray = [...accordion];
    for (let i = 0; i < newArray.length; i++) {
      const driver =newArray[i];
      if(driver?.driver_id!=item?.driver_id){
        let newOrders=[...driver?.orders];
        for (let j = 0; j < newOrders.length; j++) {
          newOrders[j].isChecked=false;
        }
        newArray[i].orders=newOrders;
      }else{
        continue;
      }
    }
    setAccordion(newArray);
  }
  

  // console.log(accordion);
  const checkDisplayButton=(orders)=>{
    if(orders&&orders?.length>0){
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];
        if(element?.isChecked){
          return true;
        }
      }
      return false;
    }else{
      return false;
    }
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <button
                type="button"
                className="btn-back"
                onClick={() => navigate("/")}
              >
                <svg className="icon fs-30">
                  <use href="#icon_leftarrow"></use>
                </svg>
              </button>
              <h2 className="text-center fw-700 fs-30">Assign Orders</h2>

              <div className="w-100 tabs-row mt-5">
                <ul
                  className="nav nav-pills mb-3 w-100"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-notassigned-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-notassigned"
                      type="button"
                      role="tab"
                      aria-controls="pills-notassigned"
                      aria-selected="true"
                    >
                      Not Assigned
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-notAssigned-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-notAssigned"
                      type="button"
                      role="tab"
                      aria-controls="pills-notAssigned"
                      aria-selected="false"
                    >
                      Assigned
                    </button>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-notassigned"
                  role="tabpanel"
                  aria-labelledby="pills-notassigned-tab "
                  tabIndex="0"
                >
                  <div className="d-flex align-items-center">
                    <div className="d-flex gap-4">
                      <div className="select-drop">
                        <label htmlFor="pickup" className="form-label">
                          Delivery Type
                        </label>
                        <Select
                          value={selectedDeliveryType}
                          options={[{label:'Import/Export',value:'importation'},{label:'Distribution',value:'distribution'},{label:'Reverse Logistics',value:'reverse_logistics'}]}
                          onChange={(e) => {
                            setselectedDeliveryType(e);
                          }}
                        />
                      </div>
                      {(selectedDeliveryType.value != 'distribution' && selectedDeliveryType.value != 'reverse_logistics')  && (
                        <>
                          <div className="select-drop">
                            <label htmlFor="pickup" className="form-label">
                              Pickup Address
                            </label>
                            <Select
                            isClearable
                              isSearchable
                              value={selectedOptionPickup}
                              options={pickupLocations}
                              onChange={(e) => {
                                setselectedOptionPickup(e);
                                setDeliveryLocations([
                                  ...new Set(
                                    notAssigned?.filter(order=>order?.pickup_location == e?.value)?.map((order) => order.delivery_location)
                                  ),
                                ]?.map((location) => ({ label: location, value: location })));
                              }}
                            />
                          </div>
                          <div className="select-drop">
                            <label htmlFor="pickup" className="form-label">
                              Delivery Address
                            </label>
                            <Select
                            isSearchable
                            isClearable
                              value={selectedOptionDelivery}
                              options={deliveryLocations}
                              onChange={(e) => setselectedOptionDelivery(e)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="ms-auto">
                    {(selectedDeliveryType.value != 'distribution' && selectedDeliveryType.value != 'reverse_logistics') && (
                      <button
                        type="button"
                        className="btn-nrml p-2 px-3 rounded-pill"

                        onClick={()=>handleShow('notassigned')}
                        disabled={
                          !filterNotAssigned.some((e) => e.isChecked === true)
                        }
                      >
                        Assign Drivers
                      </button>
                    )}
                    </div>
                  </div>

                  {selectedDeliveryType.value === 'importation' && (
                    <div className="row row-cols-3 g-4 my-3">
                      {filterNotAssigned.map((item, index) => (
                        <div className="col">
                          <div className="list-boxes">
                            <ul>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_purchase"></use>
                                </svg>
                                Purchase Order: {item?.doc_number}
                                <div className="ms-auto">
                                  <input
                                    type="checkbox"
                                    class="rounded-checkbox"
                                    id="checkbox"
                                    checked={item?.isChecked}
                                    onChange={(e) =>
                                      checkselected(e, index, item)
                                    }
                                  />
                                </div>
                              </li>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_total"></use>
                                </svg>
                                Total Quantity: {item?.total_qty} CBM ({item?.cbm}
                                )
                              </li>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_truck1"></use>
                                </svg>
                                Pickup Date: {item?.departure_date}
                              </li>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_truck2"></use>
                                </svg>
                                Pickup Location: {item?.pickup_location}
                              </li>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_delivery"></use>
                                </svg>
                                Delivery Location: {item?.delivery_location}
                              </li>
                              <li className="d-flex align-items-center">
                                <svg className="icon me-2">
                                  <use href="#icon_delivery"></use>
                                </svg>
                                Type Of Truck: {item?.container_type}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* accordion start */}
                  {selectedDeliveryType?.value === 'distribution' && (
                    <div className="accordion my-3" id="accordionExample">
                      {filterNotAssigned?.map((item,index) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle={`collapse`}
                              // data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${index}`}
                              // data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls={`collapseTwo${index}`}
                              // aria-controls="collapseTwo"
                            >
                              <div className="w22 fs-14">
                              {/* {item?.group_name} (ID: {item?.group_id}) */}
                              {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)}
                              </div>
                              <div className="d-flex align-items-center justify-content-center w-75 text-dark">
                                <div className="fs-14 fw-600 br-left px-2 ms-5">
                                  <p className="pb-2 fs-11 fw-700">
                                    PickUp Date & Time
                                  </p>
                                  <div className="d-flex fs-14 fw-400">
                                    <div className="br-right px-1">
                                      {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                    </div>
                                    <div className="px-1">
                                      {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                    </div>
                                  </div>
                                </div>
                                {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {/* <div className="br-right px-1">{item?.dispatch_date}</div>
                                <div className="px-1">11:02 AM</div> */}
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)}
                              </div>
                              <button
                                type="button"
                                className="accor-assign accor-btn"
                                onClick={()=>handleShow({group_id:item?.group_id,previous_driver_id:null})}
                              >
                                Assign Driver
                              </button>
                            </button>
                          </h2>
                          <div
                              id={`collapseTwo${index}`}
                            // id="collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row row-cols-2 g-4">
                                {item?.orders?.map((order, index) => (
                                  <div className="col">
                                    <div className="list-boxes">
                                      <ul>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_purchase"></use>
                                          </svg>
                                          Order Number: {order?.doc_number} 
                                          <div className="ms-auto">
                                          {/* <input
                                            type="checkbox"
                                            class="rounded-checkbox"
                                            id="checkbox"
                                            /> */}
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_total"></use>
                                          </svg>
                                          Total Quantity: {order?.total_qty}
                                          <div className="eye-btn2 ms-4">
                                            <button
                                              type="button"
                                              className="p-0 m-0 border-0"
                                              onClick={()=>handleShowopen({
                                                doc_number: order?.doc_number,
                                                order_items: order?.order_items,
                                              })}
                                            >
                                              <svg className="icon fs-18">
                                                <use href="#icon_eye"></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_customer"></use>
                                          </svg>
                                          Customer: {order?.organization_name}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_appointment"></use>
                                          </svg>
                                          Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_truck2"></use>
                                          </svg>
                                          Delivery Address: {order?.address1}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Type Of Truck: {order?.truck_type}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Truck Line: {order?.truck_line}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* accordion start */}
                  {selectedDeliveryType?.value === 'reverse_logistics' && (
                    <div className="accordion my-3" id="accordionExample">
                      {filterNotAssigned?.map((item,index) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle={`collapse`}
                              // data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${index}`}
                              // data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls={`collapseTwo${index}`}
                              // aria-controls="collapseTwo"
                            >
                              <div className="w22 fs-14">
                              {/* {item?.group_name} (ID: {item?.group_id}) */}
                              {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)}
                              </div>
                              {/* <div className="d-flex align-items-center justify-content-center w-75 text-dark">
                                <div className="fs-14 fw-600 br-left px-2 ms-5">
                                  <p className="pb-2 fs-11 fw-700">
                                    PickUp Date & Time
                                  </p>
                                  <div className="d-flex fs-14 fw-400">
                                    <div className="br-right px-1">
                                      {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                    </div>
                                    <div className="px-1">
                                      {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                    </div>
                                  </div>
                                </div>
                                {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)}
                              </div> */}
                              <button
                                type="button"
                                className="accor-assign accor-btn"
                                onClick={()=>handleShow({group_id:item?.group_id,previous_driver_id:null})}
                              >
                                Assign Driver
                              </button>
                            </button>
                          </h2>
                          <div
                              id={`collapseTwo${index}`}
                            // id="collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row row-cols-2 g-4">
                                {item?.orders?.map((order, index) => (
                                  <div className="col">
                                    <div className="list-boxes">
                                      <ul>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_purchase"></use>
                                          </svg>
                                          Order Number: {order?.doc_number} 
                                          <div className="ms-auto">
                                          {/* <input
                                            type="checkbox"
                                            class="rounded-checkbox"
                                            id="checkbox"
                                            /> */}
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_total"></use>
                                          </svg>
                                          Total Quantity: {order?.total_qty}
                                          <div className="eye-btn2 ms-4">
                                            <button
                                              type="button"
                                              className="p-0 m-0 border-0"
                                              onClick={()=>handleShowopen({
                                                doc_number: order?.doc_number,
                                                order_items: order?.order_items,
                                              })}
                                            >
                                              <svg className="icon fs-18">
                                                <use href="#icon_eye"></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_customer"></use>
                                          </svg>
                                          Customer: {order?.organization_name}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_appointment"></use>
                                          </svg>
                                          Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_truck2"></use>
                                          </svg>
                                          Delivery Address: {order?.address1}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Type Of Truck: {order?.truck_type}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Truck Line: {order?.truck_line}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* accordion end */}
                  {conditionalExp(
                    isLoading == false && filterNotAssigned.length == 0,
                    <p className="w-full  text-center">No Orders found</p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center">
                      <Loader />
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-notAssigned"
                  role="tabpanel"
                  aria-labelledby="pills-notAssigned-tab"
                  tabIndex="0"
                >
                  <div className="d-flex align-items-center">
                    <div className="d-flex gap-4 mb-3">
                      <div className="select-drop">
                        <label htmlFor="pickup" className="form-label">
                          Delivery Type
                        </label>
                        <Select
                          value={selectedDeliveryType}
                          options={[{label:'Import/Export',value:'importation'},{label:'Distribution',value:'distribution'},{label:'Reverse Logistics',value:'reverse_logistics'}]}
                          onChange={(e) => {
                            setselectedDeliveryType(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {selectedDeliveryType.value === 'importation' && (
                  <div className="accordion" id="accordionExample">
                    {accordion.map((item, index) => (
                      <div className="accordion-item" onClick={()=>{
                        uncheckFn(item);
                      }}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle={`collapse`}
                            data-bs-target={`#collapseTwo${index}`}
                            aria-expanded="false"
                            aria-controls={`collapseTwo${index}`}
                          >
                            {item?.first_name} {item?.last_name}
                          </button>
                          {checkDisplayButton(item?.orders) && (
                            <button
                              type="button"
                              className="accor-btn"
                              onClick={()=>{setBookingId(item?.booking_id);setTruckNo(item?.truck_no);handleShow({orders:item?.orders,previous_driver_id:item?.driver_id})}}
                            >
                              Change Driver
                            </button>
                          )}
                        </h2>
                        <div
                          id={`collapseTwo${index}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row row-cols-3 g-4">
                              {item?.orders?.map((order, index) => (
                                <div className="col">
                                  <div className="list-boxes">
                                    <ul>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_purchase"></use>
                                        </svg>
                                        Purchase Order: {order?.doc_number}
                                        <div className="ms-auto">
                                          <input
                                            disabled={order?.delivery_status >= 2}
                                            type="checkbox"
                                            class="rounded-checkbox"
                                            id="checkbox"
                                            checked={order?.isChecked}
                                            onChange={(e) =>
                                              checkselectedAssined(e, index, item,order)
                                            }
                                          />
                                        </div>
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_total"></use>
                                        </svg>
                                        Total Quantity: {order?.total_qty} CBM (
                                        {order?.cbm})
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_truck1"></use>
                                        </svg>
                                        Pickup Date: {order?.departure_date}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_truck2"></use>
                                        </svg>
                                        Pickup Location: {order?.pickup_location}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_delivery"></use>
                                        </svg>
                                        Delivery Location:{" "}
                                        {order?.delivery_location}
                                      </li>
                                      <li className="d-flex align-items-center">
                                        <svg className="icon me-2">
                                          <use href="#icon_delivery"></use>
                                        </svg>
                                        Type Of Truck: {order?.container_type}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  )}

                  {/* accordion start */}
                  {selectedDeliveryType?.value === 'distribution' && (
                    <div className="accordion my-3" id="accordionExample">
                      {accordion?.map((item,index) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle={`collapse`}
                              // data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${index}`}
                              // data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls={`collapseTwo${index}`}
                              // aria-controls="collapseTwo"
                            >
                              <div className="w22 fs-14">
                              {/* {item?.group_name} (ID: {item?.group_id}) */}
                              {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)} 
                              {/* (Driver Name: {item?.first_name} {item?.last_name}) */}
                              </div>
                              <div className="d-flex align-items-center justify-content-center w-75 text-dark">
                                <div className="br-right br-left px-2 fs-14 fw-700 ms-5">
                                  Driver Name: {item?.first_name} {item?.last_name}
                                </div>
                                <div className="fs-14 fw-600 br-left px-2 ms-4">
                                  <p className="pb-2 fs-11 fw-700">
                                    PickUp Date & Time
                                  </p>
                                  <div className="d-flex fs-14 fw-400">
                                    <div className="br-right px-1">
                                      {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                    </div>
                                    <div className="px-1">
                                      {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                    </div>
                                  </div>
                                </div>
                                {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {/* <div className="br-right px-1">{item?.dispatch_date}</div>
                                <div className="px-1">11:02 AM</div> */}
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)}
                              </div>
                              {item?.group_status <= 2 && <button
                                type="button"
                                className="accor-assign accor-btn"
                                onClick={()=>{setBookingId(item?.booking_id);setTruckNo(item?.truck_no);handleShow({previous_driver_id:item?.driver_id,group_id:item?.group_id})}}
                              >
                                Change Driver
                              </button>}
                            </button>
                          </h2>
                          <div
                              id={`collapseTwo${index}`}
                            // id="collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row row-cols-2 g-4">
                                {item?.orders?.map((order, index) => (
                                  <div className="col">
                                    <div className="list-boxes">
                                      <ul>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_purchase"></use>
                                          </svg>
                                          Order Number: {order?.doc_number} 
                                          <div className="ms-auto">
                                          {/* <input
                                            type="checkbox"
                                            class="rounded-checkbox"
                                            id="checkbox"
                                            /> */}
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_total"></use>
                                          </svg>
                                          Total Quantity: {order?.total_qty}
                                          <div className="eye-btn2 ms-4">
                                            <button
                                              type="button"
                                              className="p-0 m-0 border-0"
                                              onClick={()=>handleShowopen({
                                                doc_number: order?.doc_number,
                                                order_items: order?.order_items,
                                              })}
                                            >
                                              <svg className="icon fs-18">
                                                <use href="#icon_eye"></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_customer"></use>
                                          </svg>
                                          Customer: {order?.organization_name}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_appointment"></use>
                                          </svg>
                                          Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_truck2"></use>
                                          </svg>
                                          Delivery Address: {order?.address1}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Type Of Truck: {order?.truck_type}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Truck Line: {order?.truck_line}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* accordion end */}
                  {/* accordion start */}
                  {selectedDeliveryType?.value === 'reverse_logistics' && (
                    <div className="accordion my-3" id="accordionExample">
                      {accordion?.map((item,index) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle={`collapse`}
                              // data-bs-toggle="collapse"
                              data-bs-target={`#collapseTwo${index}`}
                              // data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls={`collapseTwo${index}`}
                              // aria-controls="collapseTwo"
                            >
                              <div className="w22 fs-14">
                              {/* {item?.group_name} (ID: {item?.group_id}) */}
                              {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)} 
                              {/* (Driver Name: {item?.first_name} {item?.last_name}) */}
                              </div>
                              <div className="d-flex align-items-center justify-content-center w-75 text-dark">
                                <div className="br-right br-left px-2 fs-14 fw-700 ms-5">
                                  Driver Name: {item?.first_name} {item?.last_name}
                                </div>
                                {/* <div className="fs-14 fw-600 br-left px-2 ms-4">
                                  <p className="pb-2 fs-11 fw-700">
                                    PickUp Date & Time
                                  </p>
                                  <div className="d-flex fs-14 fw-400">
                                    <div className="br-right px-1">
                                      {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                    </div>
                                    <div className="px-1">
                                      {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                    </div>
                                  </div>
                                </div>
                                {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)} */}
                              </div>
                              {item?.group_status <= 2 && <button
                                type="button"
                                className="accor-assign accor-btn"
                                onClick={()=>{setBookingId(item?.booking_id);setTruckNo(item?.truck_no);handleShow({previous_driver_id:item?.driver_id,group_id:item?.group_id})}}
                              >
                                Change Driver
                              </button>}
                            </button>
                          </h2>
                          <div
                              id={`collapseTwo${index}`}
                            // id="collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row row-cols-2 g-4">
                                {item?.orders?.map((order, index) => (
                                  <div className="col">
                                    <div className="list-boxes">
                                      <ul>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_purchase"></use>
                                          </svg>
                                          Order Number: {order?.doc_number} 
                                          <div className="ms-auto">
                                          {/* <input
                                            type="checkbox"
                                            class="rounded-checkbox"
                                            id="checkbox"
                                            /> */}
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_total"></use>
                                          </svg>
                                          Total Quantity: {order?.total_qty}
                                          <div className="eye-btn2 ms-4">
                                            <button
                                              type="button"
                                              className="p-0 m-0 border-0"
                                              onClick={()=>handleShowopen({
                                                doc_number: order?.doc_number,
                                                order_items: order?.order_items,
                                              })}
                                            >
                                              <svg className="icon fs-18">
                                                <use href="#icon_eye"></use>
                                              </svg>
                                            </button>
                                          </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_customer"></use>
                                          </svg>
                                          Customer: {order?.organization_name}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_appointment"></use>
                                          </svg>
                                          Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_truck2"></use>
                                          </svg>
                                          Delivery Address: {order?.address1}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Type Of Truck: {order?.truck_type}
                                        </li>
                                        <li className="d-flex align-items-center">
                                          <svg className="icon me-2">
                                            <use href="#icon_delivery"></use>
                                          </svg>
                                          Truck Line: {order?.truck_line}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

{conditionalExp(
                    isLoading == false && accordion.length == 0,
                    <p className="w-full  text-center">No Orders found</p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="text-center w-100">
            Assign Drivers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="">
              <Select
                isSearchable={true}
                placeholder="Search"
                isClearable
                options={drivers?.filter(driver=>{
                  if(selectedDeliveryType?.value != "reverse_logistics"){
                    return driver?.driver_status == 0
                  }else{
                    return true
                  }
                })}
                value={selectedOption}
                onChange={(selected) => {
                  // console.log(selected);
                  setSelectedOption(selected);
                }}
              />
              <div className="input-box mb-3 mt-3">
            {bookingId && bookingId != "" && (
              <label htmlFor="first_name">Booking Id</label>
            )}
            <input
              type="text"
              className="w-100"
              id="first_name"
              value={bookingId}
              placeholder="Booking Id"
              onChange={(e) => setBookingId(e.target.value)}
              required
            />
          </div>
              <div className="input-box mb-3">
            {truckNo && truckNo != "" && (
              <label htmlFor="truck_no">Truck No.</label>
            )}
            <input
              type="text"
              className="w-100"
              id="truck_no"
              value={truckNo}
              placeholder="Truck No."
              onChange={(e) => setTruckNo(e.target.value)}
              required
            />
          </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="accor-btn"
                onClick={assignOrders}
                disabled={!selectedOption?.value || isLoadingAssign || !bookingId || !truckNo}
              >
                {conditionalExp(isLoadingAssign, <Loader />, "Submit")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" scrollable={true} show={showopen} centered onHide={handleCloseopen}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <h4 className="text-center m-0 pb-3 fs-22 fw-700">
            Order Number: {skuModalData?.doc_number}
          </h4>
          <div className="new-tbl">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start">SKU</th>
                  <th className="text-start">Descripton</th>
                  <th className="text-start">Order Quantity</th>
                </tr>
              </thead>
              <tbody>
                {skuModalData?.order_items.map((itm)=>{
                  console.log(itm)
                  return (<tr>
                    <td>{itm?.item_code}</td>
                    <td>{itm?.description}</td>
                    <td>{itm?.qty}</td>
                  </tr>)
                })}
              </tbody>
             
            </table>
          </div>
        </Modal.Body>
      </Modal>

      

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        handleCloseSuccess={() => setShowSuccessModal(false)}
        showSuccess={showSuccessModal}
        successMessagePopup={sucessMsg}
      />
    </>
  );
}

export default Assign_order;
