import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UploadModal from "../components/UploadModal";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { conditionalExp } from "../utils/commonFn";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Manage_driver() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const handleClose = () => {
    setShow(false);
    setadddriver({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      country_code: "",
    });
    setsubmitAddNewDriverError(null);
    setadddriverError({
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
    });
  };
  const handleShow = () => setShow(true);

  const [isLoading, setisLoading] = useState(false);
  const [isLoadingAdd, setisLoadingAdd] = useState(false);
  const [drivers, setdrivers] = useState([]);

  const [submittingAddNewSku, setsubmittingAddNewSku] = useState(false);
  const [submitAddNewSKUError, setsubmitAddNewSKUError] = useState(null);

  const [submittingAddNewDriver, setsubmittingAddNewDriver] = useState(false);
  const [adddriver, setadddriver] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    country_code: "",
  });

  const [adddriverError, setadddriverError] = useState({
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
  });

  const [submitAddNewDriverError, setsubmitAddNewDriverError] = useState(null);
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  const handleAddNewDriverChange = (e) => {
    setadddriver({
      ...adddriver,
      [e.target.id]: e.target.value,
    });
    setadddriverError({
      ...adddriverError,
      [e.target.id]: null,
    });
  };

  async function getDrivers() {
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))?.career
      ?.career_id;
    setisLoading(true);
    try {
      let drivers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdriver/get_driver_list`,
        { career_id: career_id },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!drivers?.data?.error) {
        setisLoading(false);
        setdrivers(drivers.data.drivers);
      } else {
        setdrivers([]);
        setisLoading(false);
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      // console.log(err);
      setisLoading(false);
    }
  }

  function smallCheckFnForSku() {
    let first_name =
      adddriver?.first_name?.length == 0 ? "Please enter First Name" : null;

    let last_name =
      adddriver.last_name?.length == 0 ? "Please enter last name" : null;

    let email = adddriver.email?.length == 0 ? "Please enter eamil" : null;

    let phone = adddriver.phone?.length == 0 ? "Please enter phone" : null;

    if (first_name || last_name || email || phone) {
      setadddriverError({
        first_name,
        last_name,
        email,
        phone,
      });
      return true;
    } else {
      return false;
    }
  }

  async function postNewDriver() {
    // setsubmitAddNewDriverError(null);
    if (smallCheckFnForSku()) {
      return;
    }
    const token = localStorage.getItem("mtm_user_token");
    const career_id = JSON.parse(localStorage.getItem("mtm_user_data"))?.career
      ?.career_id;
    setisLoadingAdd(true);
    try {
      let drivers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmdriver/add_driver`,
        {
          first_name: adddriver.first_name,
          last_name: adddriver.last_name,
          email: adddriver.email,
          phone: adddriver.phone?.substring(adddriver.country_code?.length),
          address: adddriver.address,
          career_id: career_id,
          country_code: adddriver.country_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setisLoadingAdd(false);

      if (!drivers?.data?.error) {
        setadddriver({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address: "",
          country_code: "",
        });
        setadddriverError({
          first_name: null,
          last_name: null,
          email: null,
          phone: null,
        });
        getDrivers();
        handleClose();
        setshowSuccess(true);
        setSuccessMessagePopup(drivers.data?.message);
        setTimeout(() => {
          handleCloseSuccess();
        }, 3000);
      } else {
        setsubmitAddNewDriverError(drivers.data.message);
      }
    } catch (err) {
      setisLoadingAdd(false);
      if (err.drivers.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      // console.log(err);
    }
  }

  useEffect(() => {
    getDrivers();
  }, []);
  // console.log(drivers);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <button
                type="button"
                className="btn-back"
                onClick={() => navigate("/")}
              >
                <svg className="icon fs-30">
                  <use href="#icon_leftarrow"></use>
                </svg>
              </button>
              <h2 className="text-center fw-700 fs-30">Manage Drivers</h2>
              <div className="py-5">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-nrml p-2 px-3 rounded-pill me-4"
                    onClick={handleShow}
                  >
                    Add Driver
                  </button>
                  <button
                    onClick={() => setShowUpload(true)}
                    type="button"
                    className="btn-nrml p-2 px-3 rounded-pill"
                  >
                    Bulk Upload
                  </button>
                </div>

                <Col sm={12} className="normal-tbl mt-4">
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Status</th>
                    </tr>
                  
                    {drivers?.map((item) => {
                      return (
                        <tr>
                          <td>
                            {item?.first_name} {item?.last_name}
                          </td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.address}</td>
                          <td>
                            {conditionalExp(
                              item?.driver_status,
                              "Busy",
                              "Free"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  {conditionalExp(
                    isLoading == false && drivers.length == 0,
                    <p className="w-full  text-center fs-1 py-5">There are no drivers to manage, please add drivers</p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center">
                      <Loader />
                    </div>
                  )}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="text-center w-100">Add Drivers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postNewDriver();
            }}
          >
            <div className="input-box mb-3">
              {adddriver.first_name && adddriver.first_name != "" && (
                <label htmlFor="first_name">First Name</label>
              )}
              <input
                type="text"
                className="w-100"
                id="first_name"
                value={adddriver.first_name}
                placeholder="First Name"
                onChange={handleAddNewDriverChange}
                // required
              />
            </div>

            {adddriverError.first_name && (
              <div className="text-danger mb-3">
                {adddriverError.first_name}
              </div>
            )}

            <div className="input-box mb-3">
              {adddriver.last_name && adddriver.last_name != "" && (
                <label htmlFor="last_name">Last Name</label>
              )}
              <input
                type="text"
                className="w-100"
                id="last_name"
                value={adddriver.last_name}
                onChange={handleAddNewDriverChange}
                placeholder="Last Name"
                // required
              />
            </div>

            {adddriverError.last_name && (
              <div className="text-danger mb-3">{adddriverError.last_name}</div>
            )}

            <div className="input-box mb-3">
              {adddriver.email && adddriver.email != "" && (
                <label htmlFor="email">Email</label>
              )}
              <input
                type="email"
                className="w-100"
                id="email"
                placeholder="Email"
                value={adddriver.email}
                onChange={handleAddNewDriverChange}
                // required
              />
            </div>

            {adddriverError.email && (
              <div className="text-danger mb-3">{adddriverError.email}</div>
            )}

            <div className="input-box mb-3">
              {adddriver.phone && adddriver.phone != "" && (
                <label htmlFor="phone">Phone</label>
              )}
              {/* <input
              type="number"
              className="w-100"
              id="phone"
              placeholder="Enter Phone Number"
              value={adddriver.phone}
              onChange={handleAddNewDriverChange}
              required
            /> */}
              <PhoneInput
                country={"us"}
                placeholder="Enter Phone Number"
                value={adddriver.phone}
                onChange={(value, country, e, formattedValue) => {
                  setadddriver({
                    ...adddriver,
                    country_code: country?.dialCode,
                    phone: value,
                  });
                  if (value.length) {
                    setadddriverError({
                      ...adddriverError,
                      phone: null,
                    });
                  }
                }}
                // onChange={(value, country, e, formattedValue)=>console.log(value, country, e, formattedValue)}
              />
            </div>

            {adddriverError.phone && (
              <div className="text-danger mb-3">{adddriverError.phone}</div>
            )}

            <div className="input-box mb-3">
              {adddriver.address && adddriver.address != "" && (
                <label htmlFor="address">Address</label>
              )}
              <input
                type="text"
                className="w-100"
                id="address"
                value={adddriver.address}
                onChange={handleAddNewDriverChange}
                placeholder="Enter Your Address"
                // required
              />
            </div>
            <div className="d-flex">
              <div className="ms-auto">
                {submitAddNewDriverError && (
                  <div className="text-danger mb-3">
                    {submitAddNewDriverError}
                  </div>
                )}
                <button
                  type="submit"
                  className="accor-btn rounded-pill px-4"
                  disabled={isLoadingAdd}
                  // onClick={postNewDriver}
                >
                  {isLoadingAdd ? <Loader /> : "Submit"}
                </button>
                {/* <button
                className="grd-btn rounded-pill"
                onClick={() => {
                  if (addNewDriverDetails?.driver_id) {
                    onSubmitAddNewDriver("edit");
                  } else {
                    onSubmitAddNewDriver("add");
                  }
                }}
                disabled={submittingAddNewDriver}
              >
                {submittingAddNewDriver ? <Loader /> : "Submit"}
              </button> */}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <UploadModal
        setshowSuccess={setshowSuccess}
        showSuccess={showSuccess}
        setShowUpload={setShowUpload}
        showUpload={showUpload}
        getDrivers={getDrivers}
        handleCloseSuccess={handleCloseSuccess}
        setError={setError}
        setShowErrModal={setShowErrModal}
        setSuccessMessagePopup={setSuccessMessagePopup}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default Manage_driver;
