import {React,useState,useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import axios from 'axios';
import { conditionalExp } from '../utils/commonFn';
import Loader from './Loader';


function NavBar() {
  const navigate = useNavigate()
  const [gettingCustomer, setGettingCustomer] = useState(false)

  const logOut = async () => {
    setGettingCustomer(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmuser/logout`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          email: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.email,
        },
      });
      
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setGettingCustomer(false);
      localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
    }
  };

  return (
    <>
      <Navbar expand="lg">
        <Container>
            <Navbar.Brand href="#">
              <img src={window.location.origin +'/images/logo.png'} style={{ width: "140px", maxHeight: "52px" }}></img>
            </Navbar.Brand>
            {localStorage.getItem("mtm_user_data") && <div>
              {conditionalExp(gettingCustomer,<Loader/>,<button onClick={()=>logOut()} type='button' className='btn-logout'>
                <svg className="icon fs-30">
                  <use href="#icon_logout"></use>
                </svg>
              </button>)}
            </div> }             
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar