import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { conditionalExp } from '../utils/commonFn';
import Loader from './Loader';

function UploadModal({showUpload, setShowUpload,setshowSuccess,setError,setShowErrModal,setSuccessMessagePopup,handleCloseSuccess,getDrivers,from,appointmentData,setUploadedDocUrl}) {
    const [previewUrl, setpreviewUrl] = useState();
    const [isFileUploading, setisFileUploading] = useState(false);
    const [rawFile, setrawFile] = useState();
    const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const navigate = useNavigate();

  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
    const handleChangeFileFun = (e) => {
        for (let file of e.target.files) {
          if (file && file.size > MAX_FILE_SIZE) {
            setfileUploadLimitRxError(true);
            return;
          }
        }
        setfileUploadLimitRxError(false);
        setrawFile(Object.values(e.target.files));
      };
      const checkRxType = (file) => {
        let rawFilee = file ? file : rawFile;
        let objectURL = URL.createObjectURL(rawFilee);
        if (!rawFilee) {
          return (
            <img
              src={objectURL}
              style={{
                width: "40px",
                height: "30px",
                margin: "5px",
                marginRight: "10px",
              }}
              alt="doc"
            />
          );
        }
        let type = rawFilee?.name?.split(".");
        return getTypeDoc(objectURL, type);
      };
      const getTypeDoc = (path, typ) => {
        if (!path) {
          return "";
        }
        let type = typ ? typ : path?.split(".");
        if (type[type.length - 1] == "pdf") {
          return returnImgComp(window.location.origin + "/images/pdf.png");
        } else if (
          type[type.length - 1] == "xls" ||
          type[type.length - 1] == "xlsx"
        ) {
          return returnImgComp(window.location.origin + "/images/exel.png");
        } else if (
          type[type.length - 1] == "doc" ||
          type[type.length - 1] == "docx"
        ) {
          return returnImgComp(window.location.origin + "/images/doc.png");
        }
    
        return returnImgComp(path);
    
        function returnImgComp(src) {
          return (
            <img
              src={src}
              style={{
                width: "30px",
                height: "30px",
                margin: "5px",
                marginRight: "10px",
              }}
              alt="doc"
            />
          );
        }
      };

      
    
      const handleCloseUpload = () => {
        setShowUpload(false);
        setpreviewUrl(null);
        setrawFile(null);
      };

      async function handleFileUpload(event) {
        event.preventDefault();
        if (!rawFile?.length) {
          setError({ title: "Error", err: "Please select a file to upload" });
          setShowErrModal(true);
          return;
        }
        setisFileUploading(true);
        try {
          const token = localStorage.getItem("mtm_user_token");
          const formData = new FormData();
          formData.append(
            "career_id",
            JSON.parse(localStorage.getItem("mtm_user_data"))?.career?.career_id
          );
          rawFile?.forEach((file) => {
            formData.append("document", file);
          });
    
          const response = await axios({
            url: `${process.env.REACT_APP_BASE_URL}/mtmdriver/bulk_add_drivers`,
            method: "POST",
            headers: {
              jwt_token: token,
            },
    
            data: formData,
          });
    
          if (!response?.data?.error) {
              if(response?.data?.errorItems?.length){
              setError({ title: "Error", err:"other rows are uploaded successfully exept these " + response?.data?.errorItems?.map(errItem=>  "Row " + errItem?.row + ": " + errItem?.msg).join(", ") });
              setShowErrModal(true);
                return
              }
            setshowSuccess(true);
            setSuccessMessagePopup(response.data?.message);
            handleCloseUpload();
            setTimeout(() => {
              !response?.data?.errorItems?.length && handleCloseSuccess();
            }, 3000);
          } else {
            setError({ title: "Error", err: response?.data?.message });
            setShowErrModal(true);
          }
        } catch (error) {
          setShowErrModal(true);
          setError({ title: "Error", err: error?.message });
          if (error?.response?.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/login");
            return;
          }
    
          if (error?.code === "ECONNABORTED") {
            console.log("Request timed out");
          } else {
            console.log("An error occurred:", error);
          }
        } finally {
          setisFileUploading(false);
          getDrivers();
        }
      }
      async function handleFileUploadAppointment(event) {
        event.preventDefault();
        if (!rawFile?.length) {
          setError({ title: "Error", err: "Please select a file to upload" });
          setShowErrModal(true);
          return;
        }
        setisFileUploading(true);
        try {
          const token = localStorage.getItem("mtm_user_token");
          const formData = new FormData();
          
          rawFile?.forEach((file) => {
            formData.append("document", file);
          });

          // formData.append("booking_uniq_id", appointmentData?.appoint_uniq_id);
          formData.append("driver_id", appointmentData?.driver_id);
          // formData.append("quote_id", appointmentData?.quote_id);
          // formData.append("booking_id", appointmentData?.booking_id);
          // formData.append("appointment_date", appointmentData?.appointment_date);
    
          const response = await axios({
            url: `${process.env.REACT_APP_BASE_URL}/drv/upload_appointment_doc`,
            method: "POST",
            data: formData,
          });
    
          if (!response?.data?.error) {
            setUploadedDocUrl && setUploadedDocUrl(response.data?.appointment_docs)
            setshowSuccess(true);
            setSuccessMessagePopup(response.data?.message);
            handleCloseUpload();
            setTimeout(() => {
              !response?.data?.errorItems?.length && handleCloseSuccess();
            }, 3000);
          } else {
            setError({ title: "Error", err: response?.data?.message });
            setShowErrModal(true);
          }
        } catch (error) {
          setUploadedDocUrl && setUploadedDocUrl(null)
          setShowErrModal(true);
          setError({ title: "Error", err: error?.message });
          if (error?.response?.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/login");
            return;
          }
    
          if (error?.code === "ECONNABORTED") {
            console.log("Request timed out");
          } else {
            console.log("An error occurred:", error);
          }
        } finally {
          setisFileUploading(false);
        }
      }

      useEffect(() => {
        if (!rawFile || rawFile.length == 0) {
          setpreviewUrl(null);
          return;
        }
        const objectURL = URL.createObjectURL(
          rawFile.length ? rawFile[0] : rawFile
        );
        setpreviewUrl(objectURL);
      }, [rawFile]);

  return (
    <Modal
        show={showUpload}
        centered
        onHide={handleCloseUpload}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">{conditionalExp(from == 'customer_appointment','Upload Appointment Document','Bulk Upload Drivers')}</h4>
          {from !== 'customer_appointment' && <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={`Drivers Bulk Upload.xlsx`}
              download
            >
              Download Sample File
            </a>
          </p>}

          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src={window.location.origin + "/images/uploader.png"}
                alt="..."
              />
              <input
                type="file"
                accept={conditionalExp(from == 'customer_appointment',".pdf,.png,.jpeg,.jpg",".xls,.xlsx")}
                id="fileUpload2"
                onChange={handleChangeFileFun}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 p-1 my-3">
            {previewUrl ? (
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          {fileUploadLimitRxError && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={(e)=>{
                if(from == 'customer_appointment'){
                  handleFileUploadAppointment(e);
                }else{
                  handleFileUpload(e);
                }
              }}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
  )
}

export default UploadModal