import React from 'react'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container, Row, Col} from 'react-bootstrap';
import { json, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate()

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className='wrapper-box'>
              <h2 className='text-center fw-700 fs-30 mt-4'>{JSON.parse(localStorage.getItem("mtm_user_data"))?.career?.career_name}</h2>
              <div className='py-5'>
                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/manage-driver")}>
                  Manage Drivers
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/assign-order")}>
                  Assign Orders
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/delivery-history")}>
                  Delivery History
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home