import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { SuccessModal } from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import UploadModal from "../components/UploadModal";
import { conditionalExp } from "../utils/commonFn";
import Loader from "../components/Loader";
import axios from "axios";

function Customer_appointment() {
  const { appoint_uniq_id } = useParams();
  const [appointmentDate, setAppointmentDate] = useState();
  const [value, setValue] = useState({
    driver_id: null,
    driver_name: "",
    po_details: [
      {
        doc_number: "",
        quote_id: "",
        booking_id: "",
        pickup_date: "",
      },
    ],
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [uploadedDocUrl, setUploadedDocUrl] = useState()
  const [showUpload, setShowUpload] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
const [isLoading, setIsLoading] = useState(false);
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const navigate = useNavigate();

  async function getData() {
    setPageLoading(true);
    try {
      let drivers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/drv/booking_details`,
        { appoint_uniq_id: appoint_uniq_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!drivers?.data?.error) {
        setPageLoading(false);
        // console.log(drivers.data);
        setValue({
          ...drivers.data?.booking_details,
          boooking_id: [
            ...new Set(
              drivers.data?.booking_details?.po_details
                ?.map((booking) => booking?.booking_id)
                ?.filter((booking) => booking)
            ),
          ].join(", "),
          po: [
            ...new Set(
              drivers.data?.booking_details?.po_details
                ?.map((po) => po?.doc_number)
                ?.filter((po) => po)
            ),
          ].join(", "),
        });
      } else {
        setValue({
          driver_id: null,
          driver_name: "",
          po_details: [
            {
              doc_number: "",
              quote_id: "",
              booking_id: "",
              pickup_date: "",
            },
          ],
        });
        setPageLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setPageLoading(false);
    }
  }

  async function saveAppointment() {
    
    setIsLoading(true);
    try {
      let drivers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/drv/add_appointment`,
        {
          ...value,
          appointment_date:appointmentDate?.format('YYYY-MM-DD HH:mm:ss'),
          booking_uniq_id: appoint_uniq_id,
          quote_id: value?.po_details[0]?.quote_id,
          booking_id: value?.po_details[0]?.booking_id,
          appointment_docs: uploadedDocUrl,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);

      if (!drivers?.data?.error) {
        navigate("/saved", {
          replace: true,
          state: {
            message: drivers.data.message,
          },
        });
      } else {
        setShowErrModal(true);
        setError({ title: "Error", err: drivers.data?.message });
      }
    } catch (err) {
      setIsLoading(false);
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
     
      // console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <div className="wrapper-box d-flex flex-column justify-content-center align-items-center">
          {conditionalExp(
            pageLoading,
            <Loader />,
            <>
              <div className="d-flex justify-content-between mb-3">
                <p>
                  <b>Driver:</b>
                  {value?.driver_name}
                </p>
                &nbsp;
                <p>
                  <b>Booking Id:</b>
                  {value?.booking_id}
                </p>
                &nbsp;
                <p>
                  <b>Purchase Order:</b>
                  {value?.po}
                </p>
              </div>
              <h3>Appointment Date</h3>

              <div style={{ width: "250px" }} className="fw-bold mt-3">
                <DatePicker
                  disabledDate={(current) =>
                    current &&
                    current.valueOf() < dayjs().startOf("day").valueOf()
                  }
                  popupClassName="datepicker"
                  value={appointmentDate}
                  onChange={setAppointmentDate}
                  showTime
                  className="w-100 mb-3"
                  placeholder="Select Appointment Date"
                />
              </div>

              <button
                disabled={!appointmentDate}
                type="button"
                className="btn-line mt-4"
                onClick={() => {
                  setShowUpload(true);
                }}
              >
                Upload Appointment Document
              </button>
              
              <div className="w-100 mt-3 d-flex justify-content-end">
              <button
                disabled={!uploadedDocUrl || !appointmentDate}
                type="button"
                className="upload-btn  mt-4"
                onClick={saveAppointment}
              >
                {conditionalExp(isLoading, <Loader />,"Save")}
              </button>
              </div>
            </>
          )}
        </div>
      </Container>
      <UploadModal
        from="customer_appointment"
        appointmentData={{
          ...value,
          appointment_date: appointmentDate?.format('YYYY-MM-DD HH:mm:ss'),
          appoint_uniq_id: appoint_uniq_id,
          quote_id: value?.po_details[0]?.quote_id,
          booking_id: value?.po_details[0]?.booking_id,
        }}
        setshowSuccess={setshowSuccess}
        showSuccess={showSuccess}
        setShowUpload={setShowUpload}
        showUpload={showUpload}
        getDrivers={() => {}}
        handleCloseSuccess={handleCloseSuccess}
        setError={setError}
        setShowErrModal={setShowErrModal}
        setSuccessMessagePopup={setSuccessMessagePopup}
        setUploadedDocUrl={setUploadedDocUrl}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default Customer_appointment;
